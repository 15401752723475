<template>
  <div class="my-6">
    <BirdSection :pet="this.$route.params.pet" />
    <NavMenuSecondary page="newpet" :data="this.$route.params.pet" />
  </div>
</template>

<script>
import BirdSection from "../components/petrecord/bird/BirdSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    BirdSection,
    NavMenuSecondary,
  },
};
</script>